import {
    Component,
    DestroyRef,
    ElementRef,
    inject,
    OnInit,
} from "@angular/core";
import { ViewChild } from "@angular/core";
import { BlinkService } from "../../services/blink.service";
import { TranslateService } from "@ngx-translate/core";
import { FlowService } from "src/app/services/tools/flow.service";
import { KeyStep } from "src/app/services/steps/step.interface";
import { SessionService } from "src/app/services/session.service";
import { firstValueFrom, Subscription } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { Alert } from "../../interfaces/alert";
import { Strings } from "src/app/classes/messages";
import { ModalController } from "@ionic/angular";
import { StorageService } from "src/app/services/storage.service";
import { ExtractDocument, StateExtractDocument } from "src/app/models/extract-document";
import { Builder } from "builder-pattern";
import {
    BlinkIdMultiSideRecognizerResult,
} from "@microblink/blinkid-in-browser-sdk/types/Recognizers/BlinkID/Generic/BlinkIdMultiSideRecognizer";
import * as BlinkIDSDK from "@microblink/blinkid-in-browser-sdk";
import { environment } from "../../../environments/environment";
import { CustomError } from "src/app/classes/custom-error";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DocumentSessionSelected } from "src/app/models/session/document-session.model";
import { DocumentDetectorConfig } from "@jaak.ai/document-detector";
import { FileResult } from "@jaak.ai/document-detector/dist/types/interfaces/file-result";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { LivenessService } from "../../services/liveness.service";
import { DOC_ORIENTATION, NgxImageCompressService } from "ngx-image-compress";
import { DocumentVerify } from "../../models/document";

@Component({
    selector: "app-check-document-blink",
    templateUrl: "./check-document-blink.component.html",
    styleUrls: ["./check-document-blink.component.scss"],
})
export class CheckDocumentBlinkComponent implements OnInit {
    @ViewChild("videoRef")
    videoRef: ElementRef<HTMLVideoElement>;
    @ViewChild("blinkIdInBrowser")
    blinkIdInBrowser: ElementRef<{
        startCameraScan: () => Promise<void>;
        startMultiSideImageScan: (front: File, back: File) => Promise<void>;
        startImageScan: (front: File) => Promise<void>;
        scanFromImage: boolean;
        thoroughScanFromImage: boolean;
        restart: () => Promise<void>;
    }>;
    message = "";
    minApprovalEvaluation = 0.9;
    blinkSubscription: Subscription | null = null;

    blinkKey = environment.blinkKey;

    translationBlinkId: { [key: string]: string };

    config: DocumentDetectorConfig = {
        mode: "upload-file",
        accept: "image/*",
        placeholder: "",
        cameraSource: "environment",
        width: "100%",
        height: "100%",
        video: {
            aspectRatio: 16 / 9,
            width: { ideal: 1280, max: 1280, min: 640 },
            height: { ideal: 720, max: 720, min: 360 },
            advanced: [
                { width: { ideal: 1280, exact: 1280 } },
                { width: { exact: 1024 } },
                { width: { exact: 900 } },
                { width: { exact: 800 } },
                { width: { exact: 640 } },
                { width: { exact: 320 } },
            ],
        },
        documentDetect: false,
    };

    result: FileResult & {
        filename: string;
        extension: string;
    };

    image: string;

    newImage: string;

    ready = false;

    mode: "file" | "camera" = "camera";

    frontFile: File = null;

    backFile: File = null;

    side: "front" | "back" = "front";

    private frontCameraFrame: string;

    private backCameraFrame: string;

    private destroyRef = inject(DestroyRef);

    constructor(
        private _blinkService: BlinkService,
        private translate: TranslateService,
        private flowSrv: FlowService,
        private sessionSrv: SessionService,
        private modalSrv: ModalService,
        private modalCtrl: ModalController,
        private storageSrv: StorageService,
        private livenessSrv: LivenessService,
        private imageCompress: NgxImageCompressService,
    ) {
    }

    ngOnInit() {
        this.translationBlinkId = this.translate.instant("blink-id");
        this.translate.onLangChange
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.translationBlinkId = this.translate.instant("blink-id");
                this.flowSrv.goToStepByKey(KeyStep.CHECK_DOCUMENT_FRONT);
            });
    }

    public async verifyData(data: BlinkIdMultiSideRecognizerResult) {
        try {
            await this.modalSrv.openModalLoading();
            const documentSessionSelected: DocumentSessionSelected = {
                country: this.sessionSrv.session.document.country,
                type: this.sessionSrv.session.document.type,
            };

            const response: any = await firstValueFrom(
                this._blinkService.extract(data, documentSessionSelected),
            );

            if (response?.state) {
                const state: StateExtractDocument = Builder(
                    StateExtractDocument,
                )
                    .isExpired(response?.state?.isExpired)
                    .isUnderAge(response?.state?.isUnderAge)
                    .supportedDocument(response?.state?.supportedDocument)
                    .message(response?.state?.message)
                    .build();

                if (
                    state.isExpired ||
                    state.isUnderAge ||
                    !state.supportedDocument
                ) {
                    await this.modalSrv.closeLoadingModal();
                    const customError = Builder(CustomError)
                        .eventId(response.id ?? "")
                        .description("")
                        .errorCode("0000")
                        .message(state.message)
                        .statusCode(200)
                        .build();
                    const alert: Alert = this.createErrorAlert(customError);
                    await this.modalSrv.openModalAlert(alert);
                    return;
                }
            }

            await this.storageSrv.setEventId(response.eventId);
            await this.saveDocumentOnStorage(
                response?.documentData?.generalData?.documentImage
                    ?.photo as unknown as string,
                response,
            );

            await this.verifyDocument();

            await this.modalSrv.closeLoadingModal();
            await this.flowSrv.goToStepByKey(KeyStep.VERIFY);
        } catch (error) {
            console.log(error);
            await this.modalSrv.closeLoadingModal();
            const alert: Alert = this.createErrorAlert(error);
            await this.modalSrv.openModalAlert(alert);
            throw new Error(error);
        }
    }

    private createErrorAlert(customError: CustomError): Alert {
        const buttonType = customError.errorCode === "401" ? null : "try";
        return {
            type:
                customError.errorCode === "503" ||
                customError.errorCode === "500"
                    ? "server"
                    : "error",
            message: customError?.message
                ? customError.message
                : Strings.errorServer,
            buttonType,
            buttonFunction: async () => {
                this.flowSrv.goToStepByKey(KeyStep.CHECK_DOCUMENT_FRONT);
                if (this.mode === "camera") {
                    await this.blinkIdInBrowser?.nativeElement?.startCameraScan();
                }
                this.modalCtrl.dismiss();
            },
        };
    }

    async saveDocumentOnStorage(
        frontImageId: string,
        documentExtracted: ExtractDocument,
    ): Promise<void> {
        await this.storageSrv.setIneFrontImage(frontImageId);
        await this.storageSrv.setDocument(documentExtracted);
    }

    private async verifyDocument(): Promise<DocumentVerify> {
        const eventId = (await this.storageSrv.getEventId()) || null;

        return new Promise(async (resolve, reject) => {
            try {
                const verify = await this.livenessSrv
                    .verifyDocument(
                        eventId,
                        this.frontCameraFrame?.replace("data:image/png;base64,", ""),
                        this.backCameraFrame?.replace("data:image/png;base64,", ""),
                    )
                    .toPromise();

                if (verify.evaluation === "SUCCESS" || verify.evaluation === "WARNING") {
                    resolve(verify);
                } else {
                    reject({
                        message: verify.message,
                        type: "invalid",
                    });
                }
            } catch (error) {
                reject(error);
            }
        });
    }


    async scanSuccess(value: { recognizer: BlinkIdMultiSideRecognizerResult }) {
        this.ready = true;
        this.image = null;
        this.result = null;
        if (this.mode === "file" && this.side === "front" && this.sessionSrv.session.document.hasBackSide) {
            this.side = "back";
            this.flowSrv.goToStepByKey(KeyStep.CHECK_DOCUMENT_BACK);
            return;
        }
        const recognitionResults: BlinkIdMultiSideRecognizerResult =
            value.recognizer;
        if (
            recognitionResults.state !== BlinkIDSDK.RecognizerResultState.Empty
        ) {
            if (recognitionResults.fullDocumentFrontImage.encodedImage) {
                (
                    recognitionResults.fullDocumentFrontImage as any
                ).encodedImage = this._blinkService.base64ArrayBuffer(
                    recognitionResults.fullDocumentFrontImage.encodedImage,
                );
            }
            if (recognitionResults.fullDocumentBackImage.encodedImage) {
                (recognitionResults.fullDocumentBackImage as any).encodedImage =
                    this._blinkService.base64ArrayBuffer(
                        recognitionResults.fullDocumentBackImage.encodedImage,
                    );
            }
            if (recognitionResults.signatureImage.encodedImage) {
                (recognitionResults.signatureImage as any).encodedImage =
                    this._blinkService.base64ArrayBuffer(
                        recognitionResults.signatureImage.encodedImage,
                    );
            }
            if (recognitionResults.faceImage.encodedImage) {
                (recognitionResults.faceImage as any).encodedImage =
                    this._blinkService.base64ArrayBuffer(
                        recognitionResults.faceImage.encodedImage,
                    );
            }

            this.frontCameraFrame = "";
            this.backCameraFrame = "";

            if (recognitionResults.frontCameraFrame.frame.data) {
                (recognitionResults.frontCameraFrame as any).frame =
                    this._blinkService.imageDataToBase64(
                        recognitionResults.frontCameraFrame.frame,
                    );
                this.frontCameraFrame = await this.imageCompress.compressFile(
                    (recognitionResults.frontCameraFrame as any).frame,
                    DOC_ORIENTATION.Up,
                );
            }

            if (recognitionResults.backCameraFrame?.frame?.data) {
                (recognitionResults.backCameraFrame as any).frame =
                    this._blinkService.imageDataToBase64(
                        recognitionResults.backCameraFrame.frame,
                    );
                this.backCameraFrame = await this.imageCompress.compressFile(
                    (recognitionResults.backCameraFrame as any).frame,
                    DOC_ORIENTATION.Up,
                );
            }
        }
        console.log(JSON.stringify(recognitionResults).toString());
        (recognitionResults?.frontCameraFrame as any).frame = null;
        (recognitionResults?.backCameraFrame as any).frame = null;
        await this.verifyData(recognitionResults);
    }

    onFirstSide(): void {
        this.flowSrv.goToStepByKey(KeyStep.CHECK_DOCUMENT_BACK);
    }

    async changeMode() {
        this.mode = this.mode === "file" ? "camera" : "file";
        this.flowSrv.goToStepByKey(KeyStep.CHECK_DOCUMENT_FRONT);
        this.blinkIdInBrowser.nativeElement.restart();
        this.side = "front";
        if (this.mode === "camera") {
            this.blinkIdInBrowser.nativeElement.startCameraScan();
            this.blinkIdInBrowser.nativeElement.scanFromImage = false;
        } else {
            this.blinkIdInBrowser.nativeElement.thoroughScanFromImage = true;
            this.blinkIdInBrowser.nativeElement.scanFromImage = true;
        }
    }

    async imageCropped(event: ImageCroppedEvent) {
        this.newImage = event.base64;
    }

    isReady() {
        this.ready = true;
        if (this.mode === "camera") {
            this.blinkIdInBrowser.nativeElement.startCameraScan();
            this.blinkIdInBrowser.nativeElement.scanFromImage = false;
        } else {
            this.blinkIdInBrowser.nativeElement.thoroughScanFromImage = true;
            this.blinkIdInBrowser.nativeElement.scanFromImage = true;
        }
    }

    onDocumentDetector(event: any) {
        this.ready = false;
        this.image = event.detail.base64;
        this.result = event.detail;
        if (event.detail.name) {
            this.result.filename = event.detail.name?.split(".")[0] + ".";
            this.result.extension = event.detail.name?.split(".")[1];
        }
        setTimeout(() => {
            this.ready = true;
        }, 1000);
    }

    base64ToFile(base64String: string, mimeType: string, fileName: string) {
        const base64Data = base64String.replace(/^data:.+;base64,/, "");
        const byteCharacters = atob(base64Data); // Decode Base64 string
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const myBlob = new Blob([byteArray], { type: mimeType });
        return new File([myBlob], fileName, {
            type: myBlob.type,
        });

    }


    clear() {
        this.image = "";
        this.result = null;
    }

    async next(): Promise<any> {
        const image = this.base64ToFile(this.newImage, "image/png", "image.png");
        if (this.side === "front") {
            this.frontFile = image;
            await this.blinkIdInBrowser?.nativeElement?.startImageScan(image);
        } else {
            await this.blinkIdInBrowser?.nativeElement?.startMultiSideImageScan(this.frontFile, image);
        }
        this.ready = false;
    }

    async scanError(event: object) {
        console.log(event);
        this.ready = true;
        this.image = null;
        if (this.mode === "file") {
            this.blinkIdInBrowser.nativeElement.restart();
            this.blinkIdInBrowser.nativeElement.thoroughScanFromImage = true;
            this.blinkIdInBrowser.nativeElement.scanFromImage = true;
            const customError = Builder(CustomError)
                .message("step-check-document-blink.change-image-error")
                .build();
            const alert: Alert = this.createErrorAlert(customError);
            await this.modalSrv.openModalAlert(alert);
        }
        this.blinkIdInBrowser.nativeElement.restart();
        this.isReady();
    }
}
