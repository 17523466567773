import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Builder } from "builder-pattern";
import { Verify, BestFrame, StateLiveness } from "../models/verify";
import { DocumentVerify, IneBackData } from "../models/document";
import { ExtractDocument } from "../models/extract-document";
import { DocumentImage, IneFrontData, Position } from "../models/document";
import {
    AddressData,
    Document,
    DocumentData,
    GeoElectoralData,
} from "../models/document";
import { BuilderPatternService } from "./builder-pattern.service";

@Injectable({
    providedIn: "root",
})
export class LivenessService {
    constructor(
        private http: HttpClient,
        private builderPatternSrv: BuilderPatternService,
    ) {
    }

    /**
     * It takes an array of video URLs and an optional event ID, and returns an observable of a Verify
     * object
     * @param video - Array<string> - An array of video URLs.
     * @param {string} [eventId] - The eventId returned from the `start` method.
     * @returns An Observable of type Verify
     */
    public verify(video: Array<string>, eventId?: string): Observable<Verify> {
        const data = eventId ? { eventId, videos: video } : { videos: video };

        return this.http
            .post(`${environment.api}/api/v1/liveness/verify/`, data)
            .pipe(
                map((response: any) =>
                    Builder(Verify)
                        .id(response.eventId)
                        .status(response.status)
                        .evaluation(response.evaluation)
                        .facesFound(response.faces_found)
                        .message(response.message)
                        .processTime(response.process_time)
                        .build(),
                ),
            );
    }

    /**
     * It takes a video and an eventId and returns a BestFrame object
     * @param video - Array<string> - An array of base64 encoded images.
     * @param {string} [eventId] - The eventId is a unique identifier for the liveness verification. It is
     * generated by the server and returned in the response.
     * @returns The best frame of the video.
     */
    public verifyBestFrame(
        video: string,
        eventId?: string,
    ): Observable<BestFrame> {
        let header = null;
        if (eventId) {
            header = new HttpHeaders({
                "Request-Id": eventId,
            });
        }

        return this.http
            .post(
                `${environment.api}/api/v1/liveness/verify-and-bestframe`,
                {
                    video: video,
                },
                {
                    headers: header,
                },
            )
            .pipe(
                map((response: any) =>
                    Builder(BestFrame)
                        .id(response.eventId)
                        .score(response.score)
                        .message(response.message ?? "")
                        .processTime(response.processTime)
                        .bestFrame(response.bestFrame)
                        .threshold(response.threshold ? response.threshold : 90)
                        .state(
                            Builder(StateLiveness)
                                .isRealPerson(response.state.isRealPerson)
                                .message(response.state.message)
                                .build(),
                        )
                        .build(),
                ),
            );
    }

    /**
     * It takes a document, converts it to a request object, sends it to the API, and returns a document
     * @param {Document} doc - Document - this is the document object that you want to extract.
     * @returns An Observable of type Document
     */
    public extractDocumentV1(doc: Document): Observable<Document> {
        const data = doc.toObjectRequest();

        return this.http
            .post(`${environment.api}/api/v1/document/extract`, data)
            .pipe(
                map((response: any) => {
                    if (response) {
                        return this.convertResponseToDocumentModel(response);
                    }
                }),
            );
    }

    /**
     * It takes a document, converts it to a request object, sends it to the API, and returns an observable
     * of the response
     * @param {ExtractDocument} doc - ExtractDocument - this is the model that we created earlier.
     * @returns An Observable of type ExtractDocument
     */
    public extractDocument(doc: ExtractDocument): Observable<ExtractDocument> {
        const data = doc.toObjectRequest();
        let header = null;

        if (doc.id) {
            header = new HttpHeaders({
                "Request-Id": doc.id,
            });
        }
        return this.http
            .post(`${environment.api}/api/v2/document/extract`, data, {
                headers: header,
            })
            .pipe(
                map((response: any) => {
                    if (response) {
                        return this.builderPatternSrv.buildDocumentModel(
                            response,
                            doc.image,
                        );
                    }
                }),
            );
    }

    /**
     * It takes an ExtractDocument object and returns an Observable of DocumentVerify
     * @returns A DocumentVerify object
     */
    public verifyDocument(
        docId: string,
        documentFront: string,
        documentBack: string,
    ): Observable<DocumentVerify> {
        let header = null;

        header = new HttpHeaders({
            "Request-Id": docId,
        });

        return this.http
            .post(`${environment.api}/api/v3/document/verify`, {
                imageFront: documentFront,
                imageBack: documentBack,
            }, {
                headers: header,
            })
            .pipe(
                map((response: any) => {
                    if (response) {
                        return Builder(DocumentVerify)
                            .evaluation(response.evaluation)
                            .eventId(response.eventId)
                            .message(response.state.message)
                            .status(response.status)
                            .build();
                    }
                }),
            );
    }

    /**
     * It takes a response from the API and converts it into a Document object
     * @param {any} response - any
     * @returns The response is a Document object.
     */
    public convertResponseToDocumentModel(response: any): Document {
        return Builder(Document)
            .id(response.eventId)
            .status(response.status)
            .documentType(response.documentType)
            .documentData(
                response.documentData
                    ? Builder(DocumentData)
                        .ineFrontData(
                            Builder(IneFrontData)
                                .name(
                                    response.documentData.ineFrontData?.name,
                                )
                                .surname(
                                    response.documentData.ineFrontData
                                        ?.surname,
                                )
                                .motherSurname(
                                    response.documentData.ineFrontData
                                        ?.motherSurname,
                                )
                                .address(
                                    Builder(AddressData)
                                        .street(
                                            response.documentData.ineFrontData
                                                ?.address.street,
                                        )
                                        .neighborhood(
                                            response.documentData.ineFrontData
                                                ?.address.neighborhood,
                                        )
                                        .zipCode(
                                            response.documentData.ineFrontData
                                                ?.address.zipCode,
                                        )
                                        .city(
                                            response.documentData.ineFrontData
                                                ?.address.city,
                                        )
                                        .state(
                                            response.documentData.ineFrontData
                                                ?.address.state,
                                        )
                                        .build(),
                                )
                                .electorKey(
                                    response.documentData.ineFrontData
                                        ?.electorKey,
                                )
                                .curp(
                                    response.documentData.ineFrontData?.curp,
                                )
                                .rfc(response.documentData.ineFrontData?.rfc)
                                .geoElectoralData(
                                    Builder(GeoElectoralData)
                                        .stateCode(
                                            response.documentData.ineFrontData
                                                ?.geoElectoralData.stateCode,
                                        )
                                        .cityCode(
                                            response.documentData.ineFrontData
                                                ?.geoElectoralData.cityCode,
                                        )
                                        .sectionCode(
                                            response.documentData.ineFrontData
                                                ?.geoElectoralData
                                                .sectionCode,
                                        )
                                        .localityCode(
                                            response.documentData.ineFrontData
                                                ?.geoElectoralData
                                                .localityCode,
                                        )
                                        .build(),
                                )
                                .registerYear(
                                    response.documentData.ineFrontData
                                        ?.registerYear,
                                )
                                .emissionYear(
                                    response.documentData.ineFrontData
                                        ?.emissionYear,
                                )
                                .validUntil(
                                    response.documentData.ineFrontData
                                        ?.validUntil,
                                )
                                .registerMonth(
                                    response.documentData.ineFrontData
                                        ?.registerMonth,
                                )
                                .birthDate(
                                    response.documentData.ineFrontData
                                        ?.birthDate,
                                )
                                .birthPlace(
                                    response.documentData.ineFrontData
                                        ?.birthPlace,
                                )
                                .gender(
                                    response.documentData?.ineFrontData
                                        ?.gender,
                                )
                                .documentImage(
                                    Builder(DocumentImage)
                                        .photo(
                                            response.documentData.ineFrontData
                                                ?.documentImage?.photo,
                                        )
                                        .position(
                                            Builder(Position)
                                                .top(
                                                    response.documentData
                                                        .ineFrontData
                                                        ?.documentImage
                                                        ?.position?.Top,
                                                )
                                                .bottom(
                                                    response.documentData
                                                        .ineFrontData
                                                        ?.documentImage
                                                        ?.position?.Bottom,
                                                )
                                                .left(
                                                    response.documentData
                                                        .ineFrontData
                                                        ?.documentImage
                                                        ?.position?.Left,
                                                )
                                                .right(
                                                    response.documentData
                                                        .ineFrontData
                                                        ?.documentImage
                                                        ?.position?.Right,
                                                )
                                                .build(),
                                        )
                                        .build(),
                                )
                                .build(),
                        )
                        .ineBackData(
                            Builder(IneBackData)
                                .qrCodes(
                                    response.documentData.ineBackData
                                        ?.qrCodes,
                                )
                                .barCodes(
                                    response.documentData.ineBackData
                                        ?.barCodes,
                                )
                                .identificationOCR(
                                    response.documentData.ineBackData
                                        ?.identificationOCR,
                                )
                                .numIdentificationCredential(
                                    response.documentData.ineBackData
                                        ?.numIdentificationCredential,
                                )
                                .mechanicalReadingZone(
                                    response.documentData.ineBackData
                                        ?.mechanicalReadingZone,
                                )
                                .build(),
                        )
                        .build()
                    : null,
            )
            .documentMetadata(response.documentMetaData)
            .processTime(response.processingTime)
            .message(response.message ? response.message : null)
            .build();
    }
}
